import { useQuery } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { TemplateDetail } from '../types';
import templateApi from '../services/templateApi';
import { formErrorMessageForToast } from '../utils/backEndErrorMessages';
import { environment } from '../environments/environment';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const BaseQueryKey = 'template';

const updateSmartlinkInRichText = async (template: TemplateDetail, companyId: number) => {
  if (!template.sections) {
    return;
  }
  for (const section of template.sections) {
    section.content = await CKEditor5.smartlink.QualioSmartlink.getUpdateSmartlinkTextInRichText(
      section.content,
      companyId,
      environment.QRI_SERVICE_URL,
      environment.MEDTECH_API,
    );
  }
};

export const useTemplate = (templateId: string | undefined) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const { useMtbeTemplates } = useFlags();

  return useQuery(
    [BaseQueryKey, templateId],
    async () => {
      const template = await templateApi.getTemplateByCompanyIdAndId(companyId, templateId, useMtbeTemplates);

      await updateSmartlinkInRichText(template, companyId);

      return template;
    },
    {
      enabled: !!templateId,
      onError: (error) => {
        showToast({
          description: formErrorMessageForToast('Failed to get template', error),
          status: 'error',
        });
      },
    },
  );
};
