import React, { useState } from 'react';
import { TableMenuItem } from '@qualio/ui-components';
import { Template } from '../../types';
import { useCreateTemplateDraft } from '../../hooks/useCreateTemplateDraft';
import { MakeEffectiveModal } from '../../components/MakeEffectiveModal/MakeEffectiveModal';

const TemplateRowActions = () => {
  const { mutate: createNewDraftTemplate } = useCreateTemplateDraft();
  const [draftTemplateToMakeEffective, setDraftTemplateToMakeEffective] = useState<string | null>(null);

  return (
    <>
      <TableMenuItem
        aria-label="archive-template"
        data-cy="archive-template"
        onClick={(template: Template) => console.log('pending implementation', template)}
        isHidden={(template: Template) => template.status !== 'effective'}
        isDisabled={() => true}
      >
        Archive
      </TableMenuItem>
      <TableMenuItem
        aria-label="restore-template"
        data-cy="restore-template"
        onClick={(template: Template) => console.log('pending implementation', template)}
        isHidden={(template: Template) => template.status !== 'archived'}
        isDisabled={() => true}
      >
        Restore
      </TableMenuItem>
      <TableMenuItem
        aria-label="create-template-draft"
        data-cy="create-template-draft"
        onClick={(template: Template) => createNewDraftTemplate(template.template_uuid)}
        isHidden={(template: Template) => template.status !== 'effective'}
      >
        Create template draft
      </TableMenuItem>
      <TableMenuItem
        aria-label="make-template-effective"
        data-cy="make-template-effective"
        onClick={(template: Template) => setDraftTemplateToMakeEffective(template.template_uuid)}
        isHidden={(template: Template) => template.status !== 'draft'}
      >
        Make effective
      </TableMenuItem>
      <TableMenuItem
        aria-label="change-owner"
        data-cy="change-owner"
        onClick={(template: Template) => console.log('pending implementation', template)}
        isHidden={(template: Template) => template.status !== 'draft'}
        isDisabled={() => true}
      >
        Change template owner
      </TableMenuItem>
      <TableMenuItem
        aria-label="delete-draft-template"
        data-cy="delete-draft-template"
        onClick={(template: Template) => console.log('pending implementation', template)}
        isHidden={(template: Template) => template.status !== 'draft'}
        isDisabled={() => true}
      >
        Delete draft
      </TableMenuItem>
      {draftTemplateToMakeEffective && (
        <MakeEffectiveModal
          templateUuid={draftTemplateToMakeEffective}
          onClose={() => setDraftTemplateToMakeEffective(null)}
        />
      )}
    </>
  );
};

export default TemplateRowActions;
