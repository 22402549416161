import React from 'react';
import { QButton } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

const CreateTemplateButton = () => {
  const navigate = useNavigate();
  const goToCreateTemplatePage = () => {
    navigate('../doc-templates/new', { replace: true });
  };

  return (
    <QButton {...{ id: 'createTemplateButton' }} onClick={goToCreateTemplatePage} data-cy="createSection">
      Create template
    </QButton>
  );
};

export default CreateTemplateButton;
