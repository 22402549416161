import React, { useCallback } from 'react';
import { QBox, QSelect, QSelectItem } from '@qualio/ui-components';

const statusesFilterOptions: QSelectItem[] = [
  {
    label: 'All open statuses',
    value: 'allOpenStatuses',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Effective',
    value: 'effective',
  },
  {
    label: 'Superseded',
    value: 'superseded',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

type StatusFilterV2Props = {
  templateStatuses: string[];
  setTemplateStatuses: React.Dispatch<React.SetStateAction<string[]>>;
};

export const StatusFilterV2: React.FC<StatusFilterV2Props> = ({ templateStatuses, setTemplateStatuses }) => {
  const onStatusesFilterChange = useCallback(
    (option: QSelectItem | null) => {
      if (option !== null) {
        setTemplateStatuses([option.value]);
      }
    },
    [setTemplateStatuses],
  );

  return (
    <QBox w="30%">
      <QSelect
        options={statusesFilterOptions}
        onChange={onStatusesFilterChange}
        value={templateStatuses[0]}
        data-cy="template-status-filter"
        aria-label="Template status filter"
      />
    </QBox>
  );
};
