import React from 'react';
import { QTab, QTabList, QTabPanel, QTabPanels, QTabs } from '@qualio/ui-components';
import DefaultContentTab from './DefaultContentTab/DefaultContentTab';
import PropertiesTab from './PropertiesTab/PropertiesTab';
import { TemplateDetail } from '../../../../types';

type TemplateTabsProps = {
  templateDetail: TemplateDetail | undefined;
};

const TemplateTabs: React.FC<TemplateTabsProps> = ({ templateDetail }) => {
  if (templateDetail == null) {
    return <></>;
  }

  return (
    <QTabs data-cy="template-drawer-tabs">
      <QTabList>
        <QTab>Default Content</QTab>
        <QTab>Properties</QTab>
      </QTabList>
      <QTabPanels>
        <QTabPanel p="0">
          <DefaultContentTab templateDetail={templateDetail} />
        </QTabPanel>
        <QTabPanel p="0">
          <PropertiesTab templateDetail={templateDetail} />
        </QTabPanel>
      </QTabPanels>
    </QTabs>
  );
};

export default TemplateTabs;
