import { medtechApiClient, templateApiClient } from './axios';
import { Template, TemplateDetail, TemplateRequestBody } from '../types';
import { convertStringToDate } from '../utils/dateUtils';
import { sortSections } from '../utils/sectionUtils';

function conformDates(rawTemplate: any): any {
  rawTemplate['created_time'] = convertStringToDate(rawTemplate['created_time']);
  rawTemplate['modified_time'] = convertStringToDate(rawTemplate['modified_time']);
  return rawTemplate;
}

function resolveStatuses(rawStatuses: string[]): string[] {
  const statuses = [...rawStatuses]; // making copy to let the original list in intact
  const index = statuses.indexOf('allOpenStatuses');
  if (index === -1) {
    return statuses;
  }

  statuses.splice(index, 1); // removes allOpenStatuses

  if (statuses.indexOf('effective') === -1) {
    statuses.push('effective'); // adds effective if not already exists
  }

  if (statuses.indexOf('draft') === -1) {
    statuses.push('draft'); // adds draft if not already exists
  }

  return statuses;
}

const templateApi = {
  async getTemplatesByCompanyIdAndStatus(companyId: number, archived: boolean): Promise<Template[]> {
    const res = await templateApiClient.get(`/companies/${companyId}/templates?archived=${archived}`);
    const rawTemplates = res.data.data;
    for (const template of rawTemplates) {
      conformDates(template);
    }
    return rawTemplates as Template[];
  },

  async getTemplatesByCompanyIdAndStatuses(companyId: number, statuses: string[]): Promise<Template[]> {
    const res = await medtechApiClient.get(`/${companyId}/document_templates`, {
      params: { statuses: resolveStatuses(statuses) },
      paramsSerializer: {
        indexes: null, // to remove the [] on list type params
      },
    });
    const rawTemplates = res.data;
    for (const template of rawTemplates) {
      conformDates(template);
    }
    return rawTemplates as Template[];
  },

  async getTemplateByCompanyIdAndId(
    companyId: number,
    templateId: string | undefined,
    useMedTech: boolean,
  ): Promise<TemplateDetail> {
    const endpoint_url = useMedTech
      ? `/${companyId}/document_templates/${templateId}`
      : `/companies/${companyId}/templates/${templateId}`;
    const res = useMedTech ? await medtechApiClient.get(endpoint_url) : await templateApiClient.get(endpoint_url);
    const rawTemplate = useMedTech ? res.data : res.data.data;
    const template = conformDates(rawTemplate) as TemplateDetail;
    sortSections(template.sections ?? []);
    return template;
  },

  async createTemplate(
    companyId: number,
    newTemplate: TemplateRequestBody,
    useMedtech: boolean,
  ): Promise<TemplateDetail> {
    const postUrl = useMedtech ? `/${companyId}/document_templates` : `/companies/${companyId}/templates`;
    const backendServer = useMedtech ? medtechApiClient : templateApiClient;
    const resOrError = await backendServer.post(postUrl, newTemplate);
    if (resOrError.data) {
      const rawTemplate = useMedtech ? resOrError.data : resOrError.data.data;
      const template = conformDates(rawTemplate) as TemplateDetail;
      sortSections(template.sections);
      return template;
    }

    throw resOrError;
  },

  async createTemplateDraft(companyId: number, templateUuid: string): Promise<TemplateDetail> {
    const postUrl = `/companies/${companyId}/document_templates/${templateUuid}/draft`;
    const resOrError = await medtechApiClient.post(postUrl);
    if (resOrError.data) {
      const rawTemplate = resOrError.data;
      const template = conformDates(rawTemplate) as TemplateDetail;
      sortSections(template.sections);
      return template;
    }

    throw resOrError;
  },

  async createNewTemplate(companyId: number, prefix: string, name: string): Promise<TemplateDetail> {
    const postUrl = `/companies/${companyId}/document_templates`;
    const resOrError = await medtechApiClient.post(postUrl, {
      prefix: prefix,
      name: name,
    });
    if (resOrError.data) {
      const rawTemplate = resOrError.data;
      return conformDates(rawTemplate) as TemplateDetail;
    }

    throw resOrError;
  },

  async updateTemplate(
    companyId: number,
    templateId: string,
    template: TemplateRequestBody,
    useMedtech: boolean,
  ): Promise<TemplateDetail> {
    const putUrl = useMedtech
      ? `/${companyId}/document_templates/${templateId}`
      : `/companies/${companyId}/templates/${templateId}`;
    const backendServer = useMedtech ? medtechApiClient : templateApiClient;
    const resOrError = await backendServer.put(putUrl, template);
    if (resOrError.data) {
      const rawTemplate = useMedtech ? resOrError.data : resOrError.data.data;
      const createdTemplate = conformDates(rawTemplate) as TemplateDetail;
      sortSections(createdTemplate.sections);
      return createdTemplate;
    }

    throw resOrError;
  },

  async updateTemplateDraft(
    companyId: number,
    templateUuid: string,
    template: TemplateRequestBody,
  ): Promise<TemplateDetail> {
    const putUrl = `/companies/${companyId}/document_templates/${templateUuid}/draft`;
    const resOrError = await medtechApiClient.put(putUrl, template);
    if (resOrError.data) {
      const rawTemplate = resOrError.data;
      const updatedTemplate = conformDates(rawTemplate) as TemplateDetail;
      sortSections(updatedTemplate.sections);
      return updatedTemplate;
    }

    throw resOrError;
  },

  archiveRestoreTemplate(companyId: number, templateId: string, archived: boolean, useMedtech: boolean) {
    const requestBody = { archived: archived };
    const patchUrl = useMedtech
      ? `/${companyId}/document_templates/${templateId}`
      : `/companies/${companyId}/templates/${templateId}`;
    const backendServer = useMedtech ? medtechApiClient : templateApiClient;
    return backendServer.patch(patchUrl, requestBody);
  },
};

export default templateApi;
