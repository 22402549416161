import React from 'react';
import { QBox } from '@qualio/ui-components';
import { PageOrientation, QualioContentSection, QualioContentType } from '../../../../shared/CKEditor/models';
import ContentViewer from '../../../../ContentViewer/ContentViewer';
import { Section, TemplateDetail } from '../../../../../types';

type DefaultContentTabProps = {
  templateDetail: TemplateDetail;
};

function sortSection(sections: Section[]): Section[] {
  return sections?.sort((s1, s2) => s1.position - s2.position);
}

function prepareSections(sections: Section[]): QualioContentSection[] {
  return (sections ?? []).map((section) => {
    return {
      attachments: null,
      content: section.content,
      id: section.id,
      position: section.position,
      title: section.title,
      type: section.type,
    };
  });
}

function prepareContent(templateDetail: TemplateDetail) {
  return {
    content_type: QualioContentType.Template,
    id: templateDetail.id,
    orientation: PageOrientation.Portrait,
    sections: prepareSections(sortSection(templateDetail.sections)),
  };
}

const DefaultContentTab: React.FC<DefaultContentTabProps> = ({ templateDetail }) => {
  return (
    <QBox p={1} mt="6">
      <ContentViewer content={prepareContent(templateDetail)} isSectionedContent={templateDetail.sectioned_content} />
    </QBox>
  );
};

export default DefaultContentTab;
