import React from 'react';
import { QBox, QButton, QCenter, QHeading, QMenuButton, QStack, QText } from '@qualio/ui-components';

import TemplateMetadata from './TemplateMetadata/TemplateMetadata';
import { TemplateDetail } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useArchiveRestoreTemplate } from '../../../../hooks/useArchiveRestoreTemplate';

type TemplateDrawerTitleProps = {
  templateDetail: TemplateDetail | undefined;
  closeSlideoutPanel: any;
};

const TemplateDrawerTitle: React.FC<TemplateDrawerTitleProps> = ({ templateDetail, closeSlideoutPanel }) => {
  const navigate = useNavigate();
  const goToEditTemplatePage = () => {
    navigate(`../doc-templates/${templateDetail?.id}/edit`, { replace: true });
  };

  const isArchived = templateDetail?.status === 'archived';

  const archiveRestoreText = isArchived ? 'Restore' : 'Archive';

  const { useMtbeTemplates } = useFlags();

  const { mutate, isLoading } = useArchiveRestoreTemplate(useMtbeTemplates);

  const archiveRestoreTemplate = () => {
    if (isLoading || !templateDetail) {
      return;
    }

    mutate(templateDetail, {
      onSuccess: () => closeSlideoutPanel(),
    });
  };

  if (!templateDetail) {
    return null;
  }

  return (
    <QBox display="flex" flexDirection="row" justifyContent="space-between" w={'100%'}>
      <QStack direction={['row', 'column']} spacing="0">
        <QStack direction={['column', 'row']} spacing="80%">
          <QText>{templateDetail?.prefix.replace('-', '') || ''}</QText>
          <QBox> </QBox>
        </QStack>
        <QHeading as="h2" size="md">
          {templateDetail?.name || ''}
        </QHeading>
        <TemplateMetadata templateDetail={templateDetail} />
      </QStack>
      <QCenter>
        <QStack direction={['column', 'row']} spacing="4">
          <div data-cy="dropdownMenuMoreOption">
            <QMenuButton
              buttonLabel="options"
              itemSize="sm"
              items={[{ id: '0', label: archiveRestoreText }]}
              onItemClick={archiveRestoreTemplate}
              variant="icon"
            />
          </div>
          {templateDetail.status === 'effective' && (
            <QButton onClick={goToEditTemplatePage} variant="outline" data-cy="editButton">
              Edit
            </QButton>
          )}
        </QStack>
      </QCenter>
    </QBox>
  );
};

export default TemplateDrawerTitle;
