import { useMutation } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import templateApi from '../services/templateApi';
import { formErrorMessageForToast } from '../utils/backEndErrorMessages';
import { useNavigate } from 'react-router-dom';

export const useCreateTemplateDraft = () => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const navigate = useNavigate();

  return useMutation((templateUuid: string) => templateApi.createTemplateDraft(companyId, templateUuid), {
    onSuccess: (data) => {
      showToast({
        description: 'Document template draft created',
        status: 'success',
      });
      navigate(`/doc-templates/${data.id}/edit`);
    },
    onError: (error) => {
      showToast({
        description: formErrorMessageForToast('Failed to create template draft', error),
        status: 'error',
      });
    },
  });
};
