import React, { useEffect, useState } from 'react';
import { useCurrentUser } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';
import CreateEditTemplateHeader from '../../components/CreateEditTemplate/Header/CreateEditTemplateHeader';
import CreateEditTemplateContent from './CreateEditTemplateContent';
import { DEFAULT_TEMPLATE_TYPE, SectionDTO, TemplateDTO } from '../../types';
import UnsavedChangesAlert from '../../components/CreateEditTemplate/UnsavedChangesAlert/UnsavedChangesAlert';
import { useTemplate } from '../../hooks/useTemplate';

function initTemplateValues(): TemplateDTO {
  return {
    title: '',
    type: '',
    id: '',
    sections: [new SectionDTO(null, '', 1, '', DEFAULT_TEMPLATE_TYPE)],
    effectiveOnApproval: 'yes',
    periodicReviewPeriod: '',
    reviewLimit: '',
    approvalLimit: '',
    showSectionTitles: 'yes',
    eligibleForTraining: 'yes',
    requireRetraining: 'yes',
    fileDownloadUserGroups: [],
  };
}

const CreateEditTemplateView: React.FC = () => {
  const { companyId } = useCurrentUser();
  const [templateDto, setTemplateDto] = useState<TemplateDTO>(initTemplateValues());
  const [originalTemplateDto, setOriginalTemplateDto] = useState<TemplateDTO>(initTemplateValues());
  const { templateId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);

  if (!templateId && !isLoaded) {
    setIsLoaded(true);
  }

  if (templateId && !Number.isFinite(Number(templateId))) {
    window.location.href = `${window.location.origin}/404`;
  }

  const templateQuery = useTemplate(templateId);

  if (templateQuery.data && !isLoaded) {
    setOriginalTemplateDto(new TemplateDTO(templateQuery.data));
    setTemplateDto(new TemplateDTO(templateQuery.data));
    setIsLoaded(true);
  }

  useEffect(() => {
    if (JSON.stringify(templateDto) === JSON.stringify(originalTemplateDto)) {
      setCanShowDialogLeavingPage(false);
    } else {
      setCanShowDialogLeavingPage(true);
    }
  }, [originalTemplateDto, templateDto]);

  return (
    <>
      <CreateEditTemplateHeader templateId={templateId} />
      <UnsavedChangesAlert showPrompt={canShowDialogLeavingPage} />
      {isLoaded && (
        <CreateEditTemplateContent
          setCanShowDialogLeavingPage={setCanShowDialogLeavingPage}
          companyId={companyId}
          templateId={templateId}
          templateUUID={templateQuery.data?.template_uuid || ''}
          originalTemplateDto={originalTemplateDto}
          templateDto={templateDto}
          setTemplateDto={setTemplateDto}
          originalSectionAmount={originalTemplateDto.sections.filter((s) => !!s.id).length}
          isLoaded={isLoaded}
        />
      )}
    </>
  );
};

export default CreateEditTemplateView;
