import { QBox, QButton, QDivider, QStack } from '@qualio/ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateEditTemplatePage } from '../../../views/CreateEditTemplateView/CreateEditTemplatePage';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type CreateEditTemplateFooterProps = {
  page: CreateEditTemplatePage;
  nextPage: () => void;
  onSubmit: () => void;
  previousPage: () => void;
  submitInProgress: boolean;
  isLoaded: boolean;
  templateId: string | undefined;
  saveAndExitOnSubmit: () => void;
  saveAndExitSubmitInProgress: boolean;
  handleMakeEffectiveClick: () => void;
};

const CreateEditTemplateFooter = ({
  page,
  templateId,
  submitInProgress,
  isLoaded,
  nextPage,
  onSubmit,
  previousPage,
  saveAndExitOnSubmit,
  saveAndExitSubmitInProgress,
  handleMakeEffectiveClick,
}: CreateEditTemplateFooterProps) => {
  const navigate = useNavigate();
  const { changeManagementDocTemplate } = useFlags();

  const cancel = () => {
    if (templateId) {
      navigate(`../doc-templates/${templateId}`, { replace: true });
    } else {
      navigate('../doc-templates/', { replace: true });
    }
  };

  return (
    <>
      <QDivider orientation="horizontal" />
      <QBox pt={6}>
        <QStack direction={['column', 'row']} display="flex" justifyContent="flex-end">
          <QButton
            variant="link"
            onClick={cancel}
            data-cy="cancelButton"
            width={16}
            isDisabled={saveAndExitSubmitInProgress}
          >
            Cancel
          </QButton>
          <QButton
            isDisabled={page === CreateEditTemplatePage.IDAndTitle || saveAndExitSubmitInProgress}
            variant="outline"
            onClick={previousPage}
            data-cy="previousButton"
            leftIcon={changeManagementDocTemplate ? 'ArrowLeft' : undefined}
          >
            Previous
          </QButton>
          {page === CreateEditTemplatePage.PropertiesNewEditor && !changeManagementDocTemplate && (
            <QButton variant="solid" onClick={onSubmit} isDisabled={submitInProgress} data-cy="createButton">
              {templateId ? 'Update template' : 'Create template'}
            </QButton>
          )}
          {page === CreateEditTemplatePage.PropertiesNewEditor && changeManagementDocTemplate && (
            <QButton
              variant="outline"
              onClick={saveAndExitOnSubmit}
              isDisabled={saveAndExitSubmitInProgress}
              isLoading={saveAndExitSubmitInProgress}
              data-cy="saveAndExitButton"
            >
              Save and exit
            </QButton>
          )}
          {page === CreateEditTemplatePage.PropertiesNewEditor && changeManagementDocTemplate && (
            <QButton
              variant="solid"
              isDisabled={saveAndExitSubmitInProgress}
              isLoading={saveAndExitSubmitInProgress}
              onClick={handleMakeEffectiveClick}
              data-cy="makeEffectiveButton"
            >
              Make effective
            </QButton>
          )}
          {page !== CreateEditTemplatePage.PropertiesNewEditor && (
            <QButton
              isDisabled={!isLoaded}
              variant="solid"
              onClick={nextPage}
              type="button"
              data-cy="nextButton"
              rightIcon={changeManagementDocTemplate ? 'ArrowRight' : undefined}
            >
              Next
            </QButton>
          )}
        </QStack>
      </QBox>
    </>
  );
};

export default CreateEditTemplateFooter;
