import React, { useCallback, useState } from 'react';
import { QBox, QButton, QButtonGroup, QMenuButton } from '@qualio/ui-components';
import { TemplateDetail } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useCreateTemplateDraft } from '../../hooks/useCreateTemplateDraft';
import { MakeEffectiveModal } from '../MakeEffectiveModal/MakeEffectiveModal';

type TemplateDrawerHeaderButtonsProps = {
  template: TemplateDetail;
};

export const TemplateDrawerHeaderButtons: React.FC<TemplateDrawerHeaderButtonsProps> = ({ template }) => {
  const navigate = useNavigate();
  const [draftTemplateToMakeEffective, setDraftTemplateToMakeEffective] = useState<string | null>(null);
  const { mutate: createTemplateDraft, isLoading: isCreateTemplateDraftLoading } = useCreateTemplateDraft();
  const isArchived = template.status === 'archived';
  const archiveRestoreText = isArchived ? 'Restore' : 'Archive';

  const handleEditClick = useCallback(() => {
    navigate(`/doc-templates/${template?.id}/edit`, { replace: true });
  }, [navigate, template]);

  const handleCreateNewDraftClick = useCallback(() => {
    createTemplateDraft(template.template_uuid);
  }, [createTemplateDraft, template]);

  if (template.status === 'effective') {
    return (
      <>
        <QButton
          onClick={handleCreateNewDraftClick}
          variant="outline"
          data-cy="createTemplateDraftButton"
          isDisabled={isCreateTemplateDraftLoading}
          isLoading={isCreateTemplateDraftLoading}
        >
          Create template draft
        </QButton>
        <QBox data-cy="dropdownMenuMoreOption">
          <QMenuButton
            buttonLabel="options"
            itemSize="sm"
            items={[{ id: '0', label: archiveRestoreText }]}
            onItemClick={() => console.log('archive click')}
            variant="icon"
          />
        </QBox>
      </>
    );
  }

  if (template.status === 'draft') {
    return (
      <QButtonGroup>
        <QButton onClick={handleEditClick} variant="outline" data-cy="editButton">
          Edit
        </QButton>
        <QButton onClick={() => setDraftTemplateToMakeEffective(template.template_uuid)} data-cy="makeEffectiveButton">
          Make effective
        </QButton>
        {draftTemplateToMakeEffective && (
          <MakeEffectiveModal
            templateUuid={draftTemplateToMakeEffective}
            onClose={() => setDraftTemplateToMakeEffective(null)}
          />
        )}
      </QButtonGroup>
    );
  }

  return null;
};
