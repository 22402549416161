import { useMutation } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import templateApi from '../services/templateApi';
import { formErrorMessageForToast } from '../utils/backEndErrorMessages';
import { TemplateRequestBody } from '../types/templateRequestBody';

export const useUpdateTemplateDraft = (templateUUID: string) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  return useMutation(
    (templateBody: TemplateRequestBody) => templateApi.updateTemplateDraft(companyId, templateUUID, templateBody),
    {
      onSuccess: (data) => {
        showToast({
          description: 'Document template draft updated',
          status: 'success',
        });
      },
      onError: (error) => {
        showToast({
          description: formErrorMessageForToast('Failed to update template draft', error),
          status: 'error',
        });
      },
    },
  );
};
