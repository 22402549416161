import React from 'react';
import {
  QBadge,
  QButtonGroup,
  QCenter,
  QCloseButton,
  QDrawer,
  QHeader,
  QHorizontalMetadata,
  QLastModifiedMetadata,
  QOwnerMetadata,
  QSpinner,
  QTag,
  QTitle,
  QVersionMetadata,
} from '@qualio/ui-components';
import { useTemplate } from '../../hooks/useTemplate';
import { getTagColor, getTagText } from '../../utils/textUtil';
import OldDraftsAlert from '../UpdateDrafts/Alert/OldDraftsAlert';
import TemplateTabs from '../TemplateListView/SlideoutPanel/TemplateTabs/TemplateTabs';
import { TemplateDrawerHeaderButtons } from './TemplateDrawerHeaderButtons';

type TemplateDrawerProps = {
  companyId: number;
  templateId: string;
  closeSlideoutPanel: any;
};

export const TemplateDrawer: React.FC<TemplateDrawerProps> = ({ companyId, templateId, closeSlideoutPanel }) => {
  const { data: templateDetail, isLoading: isApiLoading } = useTemplate(templateId);
  const isLoading = isApiLoading || templateDetail === undefined;
  const showOldDraftAlert = templateDetail?.status === 'effective';

  return (
    <QDrawer isOpen={true} onClose={closeSlideoutPanel} size="xl">
      {!isLoading && (
        <QHeader showDivider={false}>
          <QTitle>
            {templateDetail.name}
            <QBadge ml="8px" data-cy="template-prefix">
              {templateDetail.prefix}
            </QBadge>
          </QTitle>
          <QHorizontalMetadata>
            <QTag data-cy="template-status" variantColor={getTagColor(templateDetail.status)}>
              {getTagText(templateDetail.status)}
            </QTag>
            <QVersionMetadata value={String(templateDetail.version)} />
            <QOwnerMetadata value={templateDetail?.owner_full_name ?? '--'} />
            {templateDetail.modified_time && <QLastModifiedMetadata value={templateDetail.modified_time} />}
          </QHorizontalMetadata>
          <QButtonGroup>
            <TemplateDrawerHeaderButtons template={templateDetail} />
            <QCloseButton onClick={closeSlideoutPanel} />
          </QButtonGroup>
        </QHeader>
      )}
      {showOldDraftAlert && (
        <OldDraftsAlert companyId={companyId} templateId={templateId} version={templateDetail.version} />
      )}
      {isLoading ? (
        <QCenter data-cy="loading-spinner">
          <QSpinner role={'spinner'} />
        </QCenter>
      ) : (
        <TemplateTabs templateDetail={templateDetail} />
      )}
    </QDrawer>
  );
};
