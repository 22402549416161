import { zodResolver } from '@hookform/resolvers/zod';
import {
  QButton,
  QFormControl,
  QInput,
  QModal,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  QTextarea,
  QBox,
  QCloseButton,
} from '@qualio/ui-components';
import React, { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Signature, ZSignature } from '../../types/signature';

export type MakeEffectiveModalProps = {
  templateUuid: string;
  onClose: () => void;
};

export const MakeEffectiveModal: React.FC<MakeEffectiveModalProps> = ({ templateUuid, onClose }) => {
  const formMethods = useForm<Signature>({
    resolver: zodResolver(ZSignature),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });
  const { control, formState } = formMethods;
  const { errors } = formState;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <FormProvider {...formMethods}>
      <QModal isOpen={true} onClose={handleClose} size="lg">
        <QModalHeader>
          <QText>Make template effective</QText>
          <QCloseButton onClick={handleClose} />
        </QModalHeader>
        <QModalBody>
          <form data-cy="form">
            <QText mb={5}>Enter your digital signature to make this template effective.</QText>
            <QStack spacing="18px">
              <QFormControl
                label="Email"
                isInvalid={!!errors.email}
                isRequired={true}
                error={errors.email?.message?.toString().trim()}
              >
                <Controller
                  control={control}
                  name="email"
                  render={(controllerProps) => (
                    <QInput {...controllerProps.field} data-cy="signature-email" placeholder="Email..." />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Password"
                isInvalid={!!errors.password}
                isRequired={true}
                error={errors.password?.message?.toString().trim()}
              >
                <Controller
                  control={control}
                  name="password"
                  render={(controllerProps) => (
                    <QInput
                      {...controllerProps.field}
                      data-cy="signature-password"
                      type="password"
                      placeholder="Password..."
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Reason for change"
                isInvalid={!!errors.reason}
                isRequired={true}
                error={errors.reason?.message?.toString().trim()}
              >
                <Controller
                  control={control}
                  name="reason"
                  render={(controllerProps) => (
                    <QTextarea
                      {...controllerProps.field}
                      placeholder="Add a reason for making this template effective..."
                      data-cy="signature-reason"
                    />
                  )}
                />
              </QFormControl>
            </QStack>
            <QBox mt={5} mb={3} display="flex" flexDirection="row" justifyContent="flex-end">
              <QBox mr={3}>
                <QButton variant="outline" data-cy="signature-modal-cancel-button" onClick={handleClose}>
                  Cancel
                </QButton>
              </QBox>
              <QButton data-cy="signature-modal-submit-button" type="submit">
                Make effective
              </QButton>
            </QBox>
          </form>
        </QModalBody>
      </QModal>
    </FormProvider>
  );
};
