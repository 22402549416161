import { z } from 'zod';

export type Template = {
  id: number;
  template_uuid: string;
  name: string;
  prefix: string;
  company_id: number;
  status: string;
  version: number;
  created_time: Date;
  modified_time?: Date;
};

export const ZCreateTemplateSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  prefix: z.string().min(1, 'Prefix is required'),
});
export type CreateTemplateSchema = z.infer<typeof ZCreateTemplateSchema>;
