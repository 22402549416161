import { useMutation, useQueryClient } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import templateApi from '../services/templateApi';
import { formErrorMessageForToast } from '../utils/backEndErrorMessages';
import { TemplateDetail } from '../types/templateDetail';

export const useArchiveRestoreTemplate = (useMtbeTemplates: boolean) => {
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation(
    (td: TemplateDetail) => {
      const isArchived = td.status === 'archived';
      return templateApi.archiveRestoreTemplate(td.company_id, td.id.toString(), !isArchived, useMtbeTemplates);
    },
    {
      onSuccess: async (data) => {
        const isArchived = JSON.parse(data.config.data)['archived'];
        const successText = isArchived ? 'archived' : 'restored';
        showToast({
          title: 'Success',
          description: 'Template successfully ' + successText,
          status: 'success',
        });

        await queryClient.invalidateQueries(['templates']);
      },
      onError: (error: any) => {
        showToast({
          title: 'Error',
          description: formErrorMessageForToast('Failed! Template prefix is already in use', error),
          status: 'error',
        });
      },
    },
  );
};
