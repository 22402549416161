import {
  QModal,
  QModalBody,
  QModalHeader,
  QBox,
  QCloseButton,
  QText,
  QButton,
  QStack,
  QFormControl,
  QInput,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CreateTemplateSchema } from '../../types';
import { useMutation } from 'react-query';
import templateApi from '../../services/templateApi';
import { AxiosError } from 'axios';
import { formErrorMessageForToast } from '../../utils/backEndErrorMessages';

type CreateTemplateModalProps = {
  onClose: (v: boolean) => void;
};

export const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const formMethods = useForm<CreateTemplateSchema>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      prefix: '',
    },
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const { mutate: createTemplateMutate, isLoading: isCreatingTemplate } = useMutation(
    (payload: CreateTemplateSchema) => templateApi.createNewTemplate(companyId, payload.prefix, payload.title),
    {
      onSuccess: (data) => {
        showToast({
          description: 'Document template draft created',
          status: 'success',
        });
        navigate(`/doc-templates/${data.id}/edit`);
      },
      onError: (error: AxiosError) => {
        const message = formErrorMessageForToast('Failed to create template', error);
        if (message.includes('Template prefix is already in use')) {
          setError('prefix', {
            type: 'server',
            message: message,
          });
        }
        if (message.includes('Template title is already in use')) {
          setError('title', {
            type: 'server',
            message: message,
          });
        }
        showToast({
          description: message,
          status: 'error',
        });
      },
    },
  );

  const onSubmit = useCallback(
    async (payload: CreateTemplateSchema) => {
      createTemplateMutate(payload);
    },
    [createTemplateMutate],
  );

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <QModal isOpen={true} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText data-cy="create-template-modal-header">Create document template</QText>
        <QCloseButton onClick={handleClose} />
      </QModalHeader>
      <QModalBody>
        <form data-cy="form">
          <QStack spacing="24px">
            <QFormControl label="Title" isRequired={true}>
              <Controller
                control={control}
                name="title"
                render={(controllerProps) => <QInput {...controllerProps.field} data-cy="title-field" />}
              />
            </QFormControl>
            <QFormControl
              label="Prefix"
              isRequired={true}
              helper="A prefix will be automatically added to every document number created using this template. The prefix can contain letters and digits only."
            >
              <Controller
                control={control}
                name="prefix"
                render={(controllerProps) => <QInput {...controllerProps.field} data-cy="prefix-field" />}
              />
            </QFormControl>
          </QStack>
          <QBox mt={5} mb={3} display="flex" flexDirection="row" justifyContent="flex-end">
            <QBox mr={3}>
              <QButton variant="outline" data-cy="cancel-button" onClick={handleClose}>
                Cancel
              </QButton>
            </QBox>
            <QButton
              data-cy="submit-button"
              type="submit"
              isDisabled={formHasErrors || isCreatingTemplate}
              onClick={handleSubmit(onSubmit)}
            >
              Create template
            </QButton>
          </QBox>
        </form>
      </QModalBody>
    </QModal>
  );
};
