import React from 'react';
import ReactDOM from 'react-dom';
import { AsyncProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { AnalyticsProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import App from './App';
import ErrorBoundary from './views/ErrorBoundary';
import { GlobalProvider } from './providers';
import { environment } from './environments/environment';

const getWindow = () => window as any;

getWindow().renderDocumentTemplates = async (
  containerId: string,
  currentUser: CurrentUser,
  analytics: SegmentAnalytics.AnalyticsJS,
) => {
  const ldProviderConfig: AsyncProviderConfig = {
    clientSideID: environment.launchDarklyClientKey,
    user: {
      key: currentUser?.companyId + '',
      custom: {
        loggedInUserId: currentUser?.userId + '',
        createdTime: currentUser?.company?.createdTime + '',
        status: currentUser?.company?.status,
      },
    },
    options: {},
  };
  if (process.env.NODE_ENV === 'development') {
    ldProviderConfig.flags = {
      ...ldProviderConfig.flags,
      multilevelListEnabled: true,
    };
  }
  const LDProvider = await asyncWithLDProvider(ldProviderConfig);

  getWindow().templateEditor = {};

  getWindow().templateEditor.containerHtmlElement = document.getElementById(containerId);

  ReactDOM.render(
    <ErrorBoundary>
      <LDProvider>
        <GlobalProvider currentUser={currentUser}>
          <AnalyticsProvider analytics={analytics}>
            <App />
          </AnalyticsProvider>
        </GlobalProvider>
      </LDProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

getWindow().unmountDocumentTemplates = (containerId: string) => {
  try {
    const containerHtmlElement =
      document.getElementById(containerId) || getWindow().templateEditor?.containerHtmlElement;
    if (containerHtmlElement) {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
    }
  } catch (e) {
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};
