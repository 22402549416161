import React, { useCallback, useMemo, useState } from 'react';
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QBodyLayout,
  QButton,
  QDataTable,
  QEmptyState,
  QHeading,
  QSpinner,
  QStack,
  Sorting,
  useCurrentUser,
} from '@qualio/ui-components';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Template } from '../../types';
import templateApi from '../../services/templateApi';
import CreateTemplateButton from '../../components/TemplateListView/TemplateListViewHeader/CreateTemplateButton/CreateTemplateButton';
import { StatusFilter } from './StatusFilter';
import SlideoutPanel from '../../components/TemplateListView/SlideoutPanel/SlideoutPanel';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateDrawer } from '../../components/TemplateDrawer/TemplateDrawer';
import { getTagText } from '../../utils/textUtil';
import { StatusFilterV2 } from './StatusFilterV2';
import TemplateRowActions from './TemplateRowActions';
import { CreateTemplateModal } from '../../components/CreateTemplateModal/CreateTemplateModal';
const columnHelper = createQColumnHelper<Template>();

const TemplateListView: React.FC = () => {
  const currentUser = useCurrentUser();
  const { templateId } = useParams();
  const [searchParams] = useSearchParams();
  const [templateStatus, setTemplateStatus] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
  const navigate = useNavigate();
  const { useMtbeTemplates, changeManagementDocTemplate } = useFlags();

  const defaultStatus = changeManagementDocTemplate ? 'allOpenStatuses' : 'effective';
  const [templateStatuses, setTemplateStatuses] = useState([defaultStatus]);

  const loadTemplates = async () => {
    let templatesResponse;
    if (useMtbeTemplates) {
      templatesResponse = await templateApi.getTemplatesByCompanyIdAndStatuses(currentUser.companyId, templateStatuses);
    } else {
      templatesResponse = await templateApi.getTemplatesByCompanyIdAndStatus(currentUser.companyId, templateStatus);
    }
    return templatesResponse.sort((a, z) => {
      if (!a.modified_time && !z.modified_time) return 0;
      if (!z.modified_time) return 1;
      if (!a.modified_time) return -1;
      return a.modified_time.getTime() - z.modified_time.getTime();
    });
  };

  const { data, isLoading } = useQuery({
    queryKey: ['templates', useMtbeTemplates ? templateStatuses : templateStatus],
    queryFn: loadTemplates,
  });

  const navigateToPath = useCallback(
    (path) => {
      const pageIndex = searchParams.get('pageIndex') || '0';
      const pageSize = searchParams.get('pageSize') || '15';
      const orderBy = searchParams.get('orderBy') || '-modified_time';
      navigate({
        pathname: path,
        search: `?${createSearchParams({
          pageIndex,
          pageSize,
          orderBy,
        })}`,
      });
    },
    [navigate, searchParams],
  );

  const closeSlideoutPanel = useCallback(() => {
    navigateToPath('/doc-templates');
  }, [navigateToPath]);

  const handleTitleAction = useCallback(
    (clickedTemplate: Template) => {
      navigateToPath(`/doc-templates/${clickedTemplate.id}`);
    },
    [navigateToPath],
  );

  const handleCreateTemplateClick = useCallback(() => {
    setShowCreateTemplateModal(true);
  }, [setShowCreateTemplateModal]);

  const columns = useMemo(() => {
    return [
      columnHelper.id('prefix', {
        header: 'ID',
        id: 'prefix',
        minWidth: '96px',
      }),
      columnHelper.textAction('name', handleTitleAction, {
        header: 'Title',
        id: 'name',
        minWidth: '305px',
      }),
      columnHelper.text('version', {
        header: 'Version',
        id: 'version',
        minWidth: '200px',
      }),
      columnHelper.date('modified_time', {
        header: 'Last Modified',
        id: 'modified_time',
        minWidth: '200px',
      }),
      columnHelper.status((template) => getTagText(template.status), {
        header: 'Status',
        id: 'status',
        minWidth: '200px',
        enableSorting: false,
        statuses: {
          Effective: 'green',
          Archived: 'red',
          Draft: 'default',
        },
      }),
      columnHelper.menu({
        hide: (template: Template) => template.status === 'superseded' || !changeManagementDocTemplate,
        items: <TemplateRowActions />,
      }),
    ];
  }, [handleTitleAction, changeManagementDocTemplate]);

  const showNewDrawer = templateId && changeManagementDocTemplate;

  if (isLoading) {
    return <QSpinner />;
  }

  return (
    <QBodyLayout.Default>
      <QStack isInline justify="space-between">
        <QHeading size="lg" mb={6} data-cy="templates-page-heading">
          Templates
        </QHeading>
        {changeManagementDocTemplate && (
          <QButton onClick={handleCreateTemplateClick} data-cy="create-draft-template-modal">
            Create template
          </QButton>
        )}
        {!changeManagementDocTemplate && <CreateTemplateButton />}
      </QStack>
      <QStack isInline>
        {changeManagementDocTemplate ? (
          <StatusFilterV2 templateStatuses={templateStatuses} setTemplateStatuses={setTemplateStatuses} />
        ) : (
          <StatusFilter
            templateStatuses={templateStatuses}
            setTemplateStatuses={setTemplateStatuses}
            archivedStatus={templateStatus}
            setArchivedStatus={setTemplateStatus}
          />
        )}
      </QStack>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Sorting.DefaultSortingProvider initialSort={{ column: 'modified_time', descending: true }}>
          <Filtering.FilterProvider definitions={{}} searchTermKey="search">
            <DataProvider.Fixed data={data ?? []} filterOption={() => true}>
              <QDataTable columns={columns} data-cy="template-list-table" data-testid="template-list-table">
                <QEmptyState
                  title="No Templates Found!"
                  subtitle="There are no templates in this instance"
                  data-cy="templates-table-empty-state"
                  data-testid="templates-table-empty-state"
                />
              </QDataTable>
            </DataProvider.Fixed>
          </Filtering.FilterProvider>
        </Sorting.DefaultSortingProvider>
      </Pagination.Auto>
      {templateId ? (
        <SlideoutPanel
          companyId={currentUser.companyId}
          templateId={templateId}
          closeSlideoutPanel={closeSlideoutPanel}
        />
      ) : null}
      {showNewDrawer ? (
        <TemplateDrawer
          companyId={currentUser.companyId}
          templateId={templateId}
          closeSlideoutPanel={closeSlideoutPanel}
        />
      ) : null}
      {showCreateTemplateModal ? <CreateTemplateModal onClose={setShowCreateTemplateModal} /> : null}
    </QBodyLayout.Default>
  );
};

export default TemplateListView;
